import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/navigation';


// import required modules
import './allcat.css';

const products = [
    { id: 1, name: 'Ac,Cooler & Fans', image: './assets/ac.jpeg', link: '/electricprodcuts' },

    { id: 3, name: 'Machines', image: './assets/agri.jpeg', link: '/industrySupplies' },

    { id: 5, name: 'Foods & Products', image: './assets/fo3.jpeg', link: '/seafood-spices-beverages-dairy-products-snacks-confectionery-nuts-seeds-suppliers' },
    { id: 6, name: 'Clothes', image: './assets/dress.jpeg', link: 'fashion-apprarels' },
    { id: 4, name: 'Gifts & Crafts', image: './assets/gi6.jpeg', link: '/gifts-items' },

    { id: 8, name: 'Medicines', image: './assets/health.jpeg', link: '/medicinestore?search=medicine' },
    { id: 2, name: 'Foot wears', image: './assets/1foot.jpeg', link: '/footwear' },
    { id: 9, name: 'Dresses', image: './assets/dress.jpeg', link: 'fashion-apprarels' },
    // Add more products as needed
];

export default function FeaturedProduct() {
    const [swiperInstance, setSwiperInstance] = useState(null);

    const handleReachEnd = () => {
        if (swiperInstance) {
            const isEnd = swiperInstance.isEnd;
            const navigationButton = document.querySelector('.swiper-button-next');
            if (navigationButton) {
                navigationButton.style.display = isEnd ? 'none' : 'block';
            }
        }
    };

    const handleReachStart = () => {
        if (swiperInstance) {
            const isBeginning = swiperInstance.isBeginning;
            const navigationButton = document.querySelector('.swiper-button-prev');
            if (navigationButton) {
                navigationButton.style.display = isBeginning ? 'none' : 'block';
            }
        }
    };

    return (
        <div style={{ padding: '5px 10px' }}>
            <h2 style={{ padding: '10px 20px' }}>Browse Our <span style={{ color: 'orange' }}>Categories</span> </h2>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true }}
                onSwiper={setSwiperInstance}
                onReachEnd={handleReachEnd}
                onReachBeginning={handleReachStart}
                breakpoints={{
                    640: { slidesPerView: 2, spaceBetween: 20 },
                    768: { slidesPerView: 4, spaceBetween: 40 },
                    1024: { slidesPerView: 7, spaceBetween: 30 },
                }}
                className="mySwiper"
            >
                {products.map(product => (
                    <SwiperSlide key={`product-${product.id}`}>
                        <Link to={product.link}>
                            <div className="card">
                                <img src={product.image} alt={product.name} />
                                <p>{product.name}</p>
                            </div>
                        </Link>
                    </SwiperSlide>
                ))}
            </Swiper>
            {/* <div className="itdevice">
                <img className='itimg' src="./assets/samridhi.png" alt="itdevice" />
            </div> */}
            <div className="itdevice-container">
                <div className="itdevice">
                    <img className='itimg' src="./assets/samridhi.png" alt="itdevice" />
                </div>
                <div className="itdevice">
                    <img className='itimg' src="./assets/samridhi.png" alt="itdevice" />
                </div>
            </div>
        </div>
    );
}
